import {Fraction, SlotGroup} from './core';

/**
 * Flatten a segment tree to leaves only, calculating
 * and returning the leave slot duration as a fraction
 * of the root segment
 * @param slotGroup
 * @param parentFraction
 */
const flattenToFractions = (slotGroup: SlotGroup, parentFraction: number = 1): Fraction[] => {
    if (slotGroup.children.length === 0) {
        const fraction: Fraction = {
            value: slotGroup.fractionOfParent.value * parentFraction,
        };
        return [fraction];
    } else {
        return slotGroup.children
            .flatMap(child =>
                flattenToFractions(child, slotGroup.fractionOfParent.value * parentFraction)
            );
    }
};

/**
 * Return a random int within a range
 * @param max
 * @param min
 */
const randomInt = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;

/**
 * Stolen from SO:
 * https://stackoverflow.com/a/29325222
 * @param min
 * @param max
 * @param biasPercent - range 1..100. passing 1 (or less) will return min, 100 (or more) will return max
 */
function biasedRandomInt(min: number, max: number, biasPercent: number) {
    if (biasPercent <= 1) {
        return min;
    }
    if (biasPercent >= 100) {
        return max;
    }

    const random = Math.random() * (max - min) + min; // random in range
    const influence = 1.5; // how much the bias affects the result
    const mix = Math.min(Math.random() * influence, 1); // random mixer
    const bias = ((max - min) * biasPercent / 100) + min;
    return Math.round(random * (1 - mix) + bias * mix); // mix full range and bias
}

export {randomInt, flattenToFractions, biasedRandomInt}