import {Fraction, SegmentFactory} from "./core";
import {flattenToFractions} from "./utils";
import {Settings} from "./settings";

export type BarSegment = Fraction & { isDownbeat: boolean };

/**
 * Responsible for generating segments (rhythmic tree structures) using a
 * SegmentFactory, flattening them into Fractions, and then
 * yielding each fraction as needed.
 * Each fraction represents a note onset and then the duration until the next note onset.
 */
class FractionFeeder {
    private factory: SegmentFactory;
    private fractions: Fraction[] = [];

    private isDownbeat = false;
    private readonly settings: Settings;
    // because we're only generating 1 beat at a time,
    // we need to keep count in order to determine the downbeat
    private beatCountDown: number;

    constructor(factory: SegmentFactory, settings: Settings) {
        this.factory = factory;
        this.settings = settings;
        this.beatCountDown = settings.beatsPerBar;
    }

    getNext(): BarSegment {
        if (this.fractions.length === 0) {
            const beatSegmented = this.factory.create();
            this.fractions = flattenToFractions(beatSegmented);

            this.isDownbeat = this.beatCountDown === this.settings.beatsPerBar;

            this.beatCountDown--;
            if (this.beatCountDown === 0) {
                this.beatCountDown = this.settings.beatsPerBar;
            }
        } else {
            this.isDownbeat = false;
        }

        const fraction = this.fractions.shift() ?? {
            value: 1
        };

        return {...fraction, isDownbeat: this.isDownbeat};
    }

    reset() {
        this.fractions = [];
        this.isDownbeat = false;
        this.beatCountDown = this.settings.beatsPerBar;
    }
}

export {FractionFeeder};