import {ReplaySubject, Subscription, tap, timer, withLatestFrom} from "rxjs";
import {ClickPlayer, ClickType} from "./click-player";
import {Settings} from "./settings";
import {FractionFeeder} from "./fraction-feeder";
import {BeatFactory} from "./beat-factory";
import {biasedRandomInt} from "./utils";
import {Onset} from "./core";

class Main {

    private readonly settings: Settings;
    private audio: ClickPlayer | undefined;

    // how often to call schedule(), milliseconds
    private readonly lookAhead: number = 25;
    // how far ahead to schedule audio, seconds
    private readonly scheduleAheadTime: number = 0.1;
    private schedulerSubscription: Subscription | undefined;

    private fractionFeeder: FractionFeeder;

    // stream of timestamps
    private readonly onsetStream$ = new ReplaySubject<Onset>(1);

    private scheduler$ = timer(0, this.lookAhead)
        .pipe(
            withLatestFrom(this.onsetStream$), // get the most recent (previous) onset event
            tap(([, onset]) => this.schedule(onset)),
        );

    get barDuration(): number {
        return 60 / this.settings.bpm * this.settings.beatsPerBar;
    };


    constructor() {
        this.settings = this.loadSettings();

        this.initialise();

        const factory = new BeatFactory(this.settings);

        this.fractionFeeder = new FractionFeeder(factory, this.settings);

        this.onsetStream$.subscribe(onset => {
            const isMuted = onset.canBeMuted
                && biasedRandomInt(0, 1, this.settings.spaceBias) === 1;

            const clickType: ClickType = isMuted ? 'silent'
                : onset.isDownBeat ? 'primary'
                : 'secondary'

            this.audio?.click(onset.timestamp, clickType);
        });
    }

    private start() {
        if (!this.audio) {
            this.audio = new ClickPlayer();
        }

        this.audio
            .resume()
            .then(() => {
                if (this.audio) {
                    // pre-schedule the count-in
                    const firstTimestamp = this.audio.currentTime + this.scheduleAheadTime;

                    for (let i = 0; i < this.settings.beatsPerBar; i++) {
                        const fraction = 1 / this.settings.beatsPerBar;
                        const duration = this.barDuration * fraction;

                        this.onsetStream$.next({
                            duration: duration,
                            timestamp: firstTimestamp + (i * duration),
                            canBeMuted: false,
                            isDownBeat: i === 0,
                        });
                    }

                    this.schedulerSubscription = this.scheduler$.subscribe();
                }
            });
    }

    private stop() {
        this.schedulerSubscription?.unsubscribe();
        this.audio?.stop();
        this.fractionFeeder.reset();
    }

    private schedule(previousOnset: Onset) {
        if (this.audio) {
            const onsets: Onset[] = [];

            let nextTimestamp = previousOnset.timestamp + previousOnset.duration;

            while (nextTimestamp < (this.audio.currentTime + this.scheduleAheadTime)) {
                const nextFraction = this.fractionFeeder.getNext();
                const duration = (nextFraction.value) * this.barDuration;

                onsets.push({
                    duration: duration,
                    timestamp: nextTimestamp,
                    canBeMuted: !(nextFraction.isDownbeat && this.settings.hasDownbeatAlways),
                    isDownBeat: nextFraction.isDownbeat,
                });

                nextTimestamp += duration;
            }

            onsets.forEach(onset => this.onsetStream$.next(onset));
        }
    }

    /**
     * Wire up the UI listeners
     * @private
     */
    private initialise() {

        const playButton = document.getElementById('play-checkbox') as HTMLInputElement;
        const playButtonText = document.getElementById('play-button-text') as HTMLElement;

        if (playButton) {
            playButton.addEventListener('click', (event) => {
                const isPlaying = (event.target as HTMLInputElement).checked;
                if (isPlaying) {
                    playButtonText.innerHTML = '&#9724;';
                    this.start();
                } else {
                    playButtonText.innerHTML = '&#9654;';
                    this.stop();
                }
            });
        }

        const bpm = document.getElementById('bpm') as HTMLInputElement;
        if (bpm) {
            bpm.addEventListener('change', (event) => {
                const value = (event.target as HTMLInputElement).valueAsNumber
                localStorage.setItem('bpm', '' + value);
                this.settings.bpm = value;
            });

            bpm.valueAsNumber = this.settings.bpm;
            bpm.dispatchEvent(new Event('input'));
        }

        const beatsPerBar = document.getElementById('beatsPerBar') as HTMLInputElement;
        if (beatsPerBar) {
            beatsPerBar.addEventListener('change', (event) => {
                const value = (event.target as HTMLInputElement).valueAsNumber
                localStorage.setItem('beatsPerBar', '' + value);
                this.settings.beatsPerBar = value;
            });

            beatsPerBar.valueAsNumber = this.settings.beatsPerBar;
            beatsPerBar.dispatchEvent(new Event('input'));
        }

        const granularity = document.getElementById('granularity') as HTMLInputElement;
        if (granularity) {
            granularity.addEventListener('change', (event) => {
                const value = (event.target as HTMLInputElement).valueAsNumber
                localStorage.setItem('granularity', '' + value);
                this.settings.granularity = value;
            });

            granularity.valueAsNumber = this.settings.granularity;
            granularity.dispatchEvent(new Event('input'));
        }

        const spaceBias = document.getElementById('space') as HTMLInputElement;
        if (spaceBias) {
            spaceBias.addEventListener('change', (event) => {
                const value = (event.target as HTMLInputElement).valueAsNumber
                localStorage.setItem('spaceBias', '' + value);
                this.settings.spaceBias = value;
            });

            spaceBias.valueAsNumber = this.settings.spaceBias;
            spaceBias.dispatchEvent(new Event('input'));
        }

        const tupletBias = document.getElementById('tuplets') as HTMLInputElement;
        if (tupletBias) {
            tupletBias.addEventListener('change', (event) => {
                const value = (event.target as HTMLInputElement).valueAsNumber
                localStorage.setItem('tupletBias', '' + value);
                this.settings.tupletBias = value;
            });

            tupletBias.valueAsNumber = this.settings.tupletBias;
            tupletBias.dispatchEvent(new Event('input'));
        }

        const hasDownbeatAlways = document.getElementById('hasDownbeatAlways') as HTMLInputElement;
        if (hasDownbeatAlways) {
            hasDownbeatAlways.addEventListener('click', (event) => {
                const isChecked = (event.target as HTMLInputElement).checked;
                localStorage.setItem('hasDownbeatAlways', JSON.stringify(isChecked));
                this.settings.hasDownbeatAlways = isChecked;
            });

            hasDownbeatAlways.checked = this.settings.hasDownbeatAlways;
        }
    }

    private loadSettings(): Settings {
        return {
            bpm: +(localStorage.getItem('bpm') || 80),
            beatsPerBar: +(localStorage.getItem('beatsPerBar') || 4),
            granularity: +(localStorage.getItem('granularity') || 1),
            tupletBias: +(localStorage.getItem('tupletBias') || 1),
            spaceBias: +(localStorage.getItem('spaceBias') || 1),
            hasDownbeatAlways: (localStorage.getItem('hasDownbeatAlways') !== 'false'),
        };
    }

}

new Main();
