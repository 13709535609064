class Fraction {
    readonly value: number;

    constructor(fraction: number) {
        if (fraction < 0 || fraction > 1) {
            throw new Error("Fraction out of range")
        }
        this.value = fraction;
    }
}

/**
 * A collection of slots or slot groups
 */
interface SlotGroup {
    /**
     * Proportion of parent duration.
     */
    fractionOfParent: Fraction;
    children: SlotGroup[];
}

interface SegmentFactory {
    create(): SlotGroup;
}

interface Onset {
    /**
     * Until the next onset
     */
    duration: number;
    timestamp: number;
    canBeMuted: boolean;
    isDownBeat: boolean;
}

export {SlotGroup, SegmentFactory, Fraction, Onset}