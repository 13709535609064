type ClickType = 'primary' | 'secondary' | 'silent';

class ClickPlayer {

    private readonly audioContext: AudioContext = new AudioContext();
    // we need this in the chain so that we can silence any scheduled clicks
    // when `stop()` is called
    private gainNode: GainNode;

    get currentTime(): number {
        return this.audioContext.currentTime;
    }

    private primaryClickBuffer: AudioBuffer | undefined;
    private secondaryClickBuffer: AudioBuffer | undefined;
    private readonly silentBuffer: AudioBuffer;

    private readonly hasClickBuffers: Promise<AudioBuffer>;

    constructor() {
        this.silentBuffer = this.audioContext.createBuffer(1, 1, 44100);
        this.hasClickBuffers =
            this.fetchWavAsBuffer(this.audioContext, "assets/noise_click.wav")
                .then(buffer => this.primaryClickBuffer = buffer)
                .then(() => this.fetchWavAsBuffer(this.audioContext, "assets/click.wav"))
                .then(buffer => this.secondaryClickBuffer = buffer);

        this.gainNode = new GainNode(this.audioContext)
        this.gainNode.connect(this.audioContext.destination)
    }

    async resume() {
        await this.audioContext
            .resume()
            .then(() => this.hasClickBuffers);
    }

    /**
     * Silence any already-scheduled audio
     */
    stop() {
        // reduce to silence
        this.gainNode.gain.linearRampToValueAtTime(0, this.audioContext.currentTime)
        this.gainNode.disconnect()

        // recreate
        this.gainNode = new GainNode(this.audioContext)
        this.gainNode.connect(this.audioContext.destination)
    }

    click(timestamp: number, clickType: ClickType) {
        const click = this.audioContext.createBufferSource();

        switch (clickType) {
            case 'primary':
                if (this.primaryClickBuffer) {
                    click.buffer = this.primaryClickBuffer;
                }
                break;
            case 'secondary':
                if (this.secondaryClickBuffer) {
                    click.buffer = this.secondaryClickBuffer;
                }
                break;
            case "silent":
                click.buffer = this.silentBuffer;
                break;
        }

        click.connect(this.gainNode);
        click.start(timestamp);
    }

    private async fetchWavAsBuffer(audioContext: AudioContext, url: string = 'assets/click.wav') {
        const response = await fetch(url);
        const arrayBuffer: ArrayBuffer = await response.arrayBuffer();
        return await audioContext.decodeAudioData(arrayBuffer);
    }
}

export {ClickPlayer, ClickType};