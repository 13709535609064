import {Fraction, SegmentFactory, SlotGroup} from './core';
import {biasedRandomInt} from './utils';
import {Settings} from "./settings";

// determines possible subdivisions.
// 0 is 8th notes
// 1 is 16th notes
// 2 is 32nd notes
const MAX_DEPTH = 2;
const MAX_GRANULARITY = 100;

/**
 * Creates beats as SlotGroups
 */
class BeatFactory implements SegmentFactory {
    settings: Settings;

    constructor(settings: Settings) {
        this.settings = settings;
    }

    // To make UI control changes respond within a reasonable time,
    // we only generate 1 beat at a time, instead of generating the whole
    // bar. The trade-off is that we can't apply triplets to larger segments,
    // e.g. to a whole bar, or half a bar.
    create(): SlotGroup {
        // an index into each granularity range, e.g. 0 -> 1 - 25, 1 -> 26 - 50, etc.
        // depends on MAX_DEPTH
        const depthIndex =  this.settings.granularity / (MAX_GRANULARITY / MAX_DEPTH);
        // roughly converts the granularity slider into a probability, which translates into how
        // much a beat might be subdivided
        const subdivision = biasedRandomInt(1, depthIndex + 2, this.settings.granularity);

        return this.createSlotGroup(
            1 / this.settings.beatsPerBar,
            MAX_DEPTH - subdivision + 1,
        );
    }

    private createSlotGroup(
        fractionOfParent: number,
        currentDepth: number,
        divideBy: number = biasedRandomInt(2, 3, this.settings.tupletBias),
    ): SlotGroup {
        const children: SlotGroup[] = [];

        if (currentDepth < MAX_DEPTH) {
            const childFraction = 1 / divideBy;
            for (let i = 0; i < divideBy; i++) {
                const segment = this.createSlotGroup(childFraction, currentDepth + 1);
                children.push(segment)
            }
        }

        return {
            fractionOfParent: new Fraction(fractionOfParent),
            children: children,
        };
    }
}

export {BeatFactory};
